import getConfig from "next/config";
import { observable, toJS, makeObservable } from "mobx";
import RootStore from "./RootStore";
import NotificationStore from "./NotificationStore";

class FileUploadStore {
    files = {};

    constructor() {
        makeObservable(this, {
            files: observable
        });

        this.config = getConfig().publicRuntimeConfig;
    }

    getFiles(category, entityId) {
        const key = `${category}-${entityId}`;

        if (this.files[key]) {
            return toJS(this.files[key]);
        }

        return [];
    }

    setFiles(category, entityId, files) {
        const key = `${category}-${entityId}`;
        this.files[key] = files;
    }

    getImgBaseUrl(ImgId) { // eslint-disable-line class-methods-use-this
        return `${this.config.apiUrl}${this.config.apiUrlPath}/upload-files/${ImgId}/`;
    }

    upload(file, expiryDate, { category, entity, entityId }) {
        this.isUploading = true;

        const formData = new FormData();
        formData.append("file", file.originFileObj);
        formData.append("category", category);
        formData.append("entity", entity);
        formData.append("entityId", entityId);
        if (expiryDate) {
            formData.append("expiryDate", expiryDate.toString());
        }

        NotificationStore.reset();

        return RootStore.api.post("/upload-files/", formData)
            .then((response) => {
                const key = `${category}-${entityId}`;

                const { files } = this;
                files[key].push(response);
                files[key] = files[key].filter(fileFilter => typeof fileFilter.url !== "undefined");

                this.files = files;

                this.isUploading = false;
            }).catch((e) => {
                this.isUploading = false;
                NotificationStore.processException(e, "login");
            });
    }

    remove(id) {
        return RootStore.api.remove(`/upload-files/${id}/`)
            .then(() => {
                const { files } = this;
                this.files = files.filter(file => file.uid !== id);

                this.isUploading = false;
            }).catch((e) => {
                this.isUploading = false;
                NotificationStore.processException(e, "login");
            });
    }

    load(entity, entityId, category) {
        const data = {
            entity,
            entityId,
            category,
        };

        return RootStore.api.get("/upload-files/", data)
            .then((response) => {
                const key = `${category}-${entityId}`;
                const files = { ...this.files };
                files[key] = response;
                this.files = files;

                this.isUploading = false;
            }).catch((e) => {
                this.isUploading = false;
                NotificationStore.processException(e, "login");
            });
    }
}

export default new FileUploadStore();
