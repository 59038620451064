import React from "react";
import { List, Radio, Popover } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import i18n from "../../core/i18n";
import CurrencyStore from "../../stores/Currency/CurrencyStore";
import AllowedCurrencies from "./PaymentList/GetAllowedCurrencies";
import isTypeAllowed from "../../utils/MethodOfPayment/isTypeAllowed";
import UserStore from "../../stores/UserStore";
import PaymentStore from "../../stores/Payment/PaymentStore";
import HelpButton from "../HelpButton";
import NewOrderStore from "../../stores/Order/NewOrderStore";
import OrdersListStore from "../../stores/Order/OrdersListStore";
import { CookieStorage } from "../../models/CookieStorage";

const RadioGroup = Radio.Group;
const ListItem = List.Item;


const getColor = (item) => {
    if (isTypeAllowed(item)) {
        return "#f9f9f9";
    }

    return "#cac7c7";
};

const getCursor = (item) => {
    if (isTypeAllowed(item)) {
        return "pointer";
    }
    return "not-allowed";
};

const paymentListOptions = ({
    data, value, defaultCurrency, onClick, showCount = 3, showOnlyPayInAdvance, showPayLater, paymentStatus, calledFromModal
}) => {
    const showBillingMethod = item => item.name !== PaymentStore.TYPE_BILLING_NAME
        || (item.name === PaymentStore.TYPE_BILLING_NAME && !calledFromModal);

    const notAllowedTypes = [];
    const allowedTypesWithSelectedCurrency = [];
    const allowedTypes = [];

    data.forEach((originalType) => {
        const type = { ...originalType };
        if (value === originalType.id && originalType.allowedCurrencies.indexOf(defaultCurrency) !== -1) {
            type.selectedCurrencyToPay = defaultCurrency;
        }

        if (!isTypeAllowed(type)) {
            notAllowedTypes.push(type);
        } else if (isTypeAllowed(type) && !!type.selectedCurrencies.find(selectedCurrency => selectedCurrency.code === CurrencyStore.selectedCurrency)) {
            const canInvoice = CookieStorage.read("can_invoice");
            if (type.id === PaymentStore.TYPE_BILLING && UserStore && UserStore.user && canInvoice && UserStore.user.can_invoice) {
                allowedTypesWithSelectedCurrency.unshift(type);
            } else {
                allowedTypesWithSelectedCurrency.push(type);
            }
            //if (type.id === PaymentStore.TYPE_COMGATE) {
            //    PaymentStore.moveObjectInArray(allowedTypesWithSelectedCurrency, PaymentStore.TYPE_COMGATE_NAME, PaymentStore.TYPE_PAYPAL_NAME);
            //}
        } else {
            allowedTypes.push(type);
        }
    });

    const dataTemp = allowedTypesWithSelectedCurrency.concat(allowedTypes, notAllowedTypes);

    let lastIndex = 0;

    const hasSelectedCarsZeroPrice = NewOrderStore.hasSelectedCarsZeroPrice();

    return (
        <React.Fragment>
            {dataTemp.filter(item => !paymentStatus || (paymentStatus === OrdersListStore.PAYMENT_STATUS_NOT_PAID) || (paymentStatus !== OrdersListStore.PAYMENT_STATUS_NOT_PAID && (item.id === value))).filter(item => item.id !== PaymentStore.TYPE_PAY_LATER).map((item, index) => {
                if (index >= showCount || !showBillingMethod(item)) {
                    return null;
                }

                if (item.inAdvance || !showOnlyPayInAdvance) {
                    lastIndex = index;
                    return (
                        <ListItem
                            className="order-form__custom-car order-form__payment"
                            aria-disabled
                            style={item.id === value ? { boxShadow: "inset 0 0 0 2px #005093", backgroundColor: getColor(item) } : { backgroundColor: getColor(item), cursor: getCursor(item) }}
                            onClick={() => {
                                CurrencyStore.selectCurrency(item.selectedCurrencyToPay);
                                if (isTypeAllowed(item)) {
                                    onClick(item.id);
                                }
                            }}
                            actions={[
                                <AllowedCurrencies item={item} paymentStatus={paymentStatus} />,
                            ]}
                            key={index} // eslint-disable-line react/no-array-index-key
                        >
                            <div className="order-form__payment-radio">
                                <Radio disabled={!isTypeAllowed(item)} value={item.id ? item.id : 3} />
                            </div>
                            <span className="order-form__payment-text">{i18n.t(item.title)}</span>
                            {item.icons && (
                                <div className="order-form__payment-icons">
                                    {item.icons.map(icon => (
                                        <svg width="25" height="25" key={icon.src}>
                                            <use xlinkHref={`${icon.src}#icon`} />
                                        </svg>
                                    ))}
                                </div>
                            )}
                            <div className="order-form__payment-help">
                                {item.id === PaymentStore.TYPE_BILLING && !UserStore.user ? (
                                    <Popover overlayClassName="order-form__popover" content={i18n.t("Dostupné pouze pro schválené partnery po vašem přihlášení. Faktura bude vystavena zpětně po implementaci služby.")} title={false}>
                                        <HelpButton />
                                    </Popover>
                                ) : (
                                    <Popover overlayClassName="order-form__popover" content={i18n.t(item.help)} title={false}>
                                        <HelpButton />
                                    </Popover>
                                )}

                            </div>
                        </ListItem>
                    );
                }
            })}
            {(showPayLater && showCount > (lastIndex + 1)) && (
                <ListItem
                    className="order-form__custom-car order-form__payment"
                    style={value === PaymentStore.TYPE_PAY_LATER ? { boxShadow: "inset 0 0 0 2px #005093" } : null}
                    onClick={() => { onClick(PaymentStore.TYPE_PAY_LATER); }}
                    key={PaymentStore.TYPE_PAY_LATER}
                >

                    <div className="order-form__payment-radio">
                        <Radio value={PaymentStore.TYPE_PAY_LATER} />
                    </div>
                    <span className="order-form__payment-text">{i18n.t("Zaplatím později")}</span>
                    <div className="order-form__payment-icons">
                        <svg width="25" height="25" key="later">
                            <use xlinkHref="/img/paylater.svg#icon" />
                        </svg>
                    </div>
                    <div className="order-form__payment-help">
                        {hasSelectedCarsZeroPrice && (
                            <Popover overlayClassName="order-form__popover" content={i18n.t("Možnost úhrady bude umožněna, jakmile bude stanovena cena dispečinkem, a to nejpozději do 24 hodin. Případně nás kontaktujte telefonicky. Děkujeme.")} title={false}>
                                <HelpButton />
                            </Popover>
                        )}
                        {!hasSelectedCarsZeroPrice && (
                            <Popover overlayClassName="order-form__popover" content={i18n.t("Objednávka vyžaduje platbu předem, pokud nebude uhrazena den před termínem vyzvednutí dojde k jejímu stornování.")} title={false}>
                                <HelpButton />
                            </Popover>
                        )}

                    </div>
                </ListItem>
            )}
        </React.Fragment>
    );
};

const PaymentsList = ({
    data, value, defaultCurrency, onClick, showCount = 3, showOnlyPayInAdvance, showPayLater, paymentStatus, calledFromModal
}) => (
    <RadioGroup value={value} style={{ width: "100%" }}>
        <List itemLayout="horizontal" bordered>
            {paymentListOptions({
                data, value, defaultCurrency, onClick, showCount, showOnlyPayInAdvance, showPayLater, paymentStatus, calledFromModal,
            })}
        </List>
    </RadioGroup>
);

PaymentsList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.number,
    showCount: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    showOnlyPayInAdvance: PropTypes.bool,
    showPayLater: PropTypes.bool.isRequired,
};

PaymentsList.defaultProps = {
    value: null,
    showOnlyPayInAdvance: false,
};

export default observer(PaymentsList);
