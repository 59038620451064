import i18n from "../../core/i18n";

export default function getRouteDuration(duration) {
    let routeDuration = "";
    if (duration) {
        const hours = Math.floor(duration / 60);
        if (hours) {
            routeDuration += `${hours} ${i18n.t("hodina", { count: hours })} `;
        }
        routeDuration += `${parseInt(duration % 60, 10)} ${i18n.t("minuta", { count: parseInt(duration % 60, 10) })}`;
    } else {
        routeDuration = "0 h 0 m";
    }
    return routeDuration;
}
